<template>
  <div class="home">
    <van-nav-bar title="新增成员" fixed left-arrow @click-left="$router.go(-1)" />
    <div class='center'>
      <div class="box">
        <van-field v-model="message" rows="5" autosize type="textarea" :placeholder="`请输入姓名 手机号`" />
      </div>

      <van-button class="button" round block type="info" native-type="submit" :loading='loading'
        @click="submit">新增</van-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "add",
    data() {
      return {
        message: '',
        loading: false,
      }
    },
    created() {
      if (localStorage.getItem('pushUserlist')) {
        const list = JSON.parse(localStorage.getItem('pushUserlist'))
        this.message = list.map((item) => { return `${item.name} ${item.tel}` }).join('\n')
      }
    },
    methods: {
      submit() {
        this.loading = true
        let arr = this.message.split('\n')
        if (arr.length > 0) {
          let list = []
          arr.forEach((item) => {
            let arr2 = item.split(' ')
            if (arr2.length > 1) {
              list.push({
                name: arr2[0],
                tel: arr2[1],
              })
            }
          })
          if (list.length > 0) {
            localStorage.setItem('pushUserlist', JSON.stringify(list))
          }
        }
        this.loading = false
      },
    },
  }
</script>
<style scoped>
  .home {
    width: 100%;
    padding: 60px 10px 20px;
    box-sizing: border-box;
  }
  .center {
    width: 100%;
    img {
      width: 240px;
      height: 260px;
      object-fit: contain;
    }
  }
  .box {
    width: 100%;
    border: 1px solid #eee;
    border-radius: 10px;
  }
  .button {
    margin-top: 20px;
  }
</style>
